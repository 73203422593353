import React, { useState } from 'react'
import {
  Button,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'
import Divider from '../../components/Divider'

import SecurityIcon from '../../assets/Business/ID Verification/Icons/fortified_security.svg'
import KYCIcon from '../../assets/Business/ID Verification/Icons/auto_kyc.svg'
import ManageDataIcon from '../../assets/Business/ID Verification/Icons/manage_data.svg'
import AuditIcon from '../../assets/Business/ID Verification/Icons/audit_verify.svg'

import FaceScanIcon from '../../assets/Business/ID Verification/Icons/facial_scan.svg'
import LivenessIcon from '../../assets/Business/ID Verification/Icons/liveness.svg'

import ZeroKnowledgeIcon1 from '../../assets/Business/ID Verification/Icons/zero_knowledge1.svg'
import ZeroKnowledgeIcon2 from '../../assets/Business/ID Verification/Icons/zero_knowledge2.svg'
import ZeroKnowledgeIcon3 from '../../assets/Business/ID Verification/Icons/zero_knowledge3.svg'
import routes from '../../routes'
import CTABox from '../../components/CTABox'
import CTALink from '../../components/CTALink'
import ContactUs from '../../components/ContactUs'

const HeroImage =
  '../../assets/Business/ID Verification/Images/id_verify_header.png'
const docVerifyImg =
  '../../assets/Business/ID Verification/Images/document_verify.png'
const facialRecognizeImg =
  '../../assets/Business/ID Verification/Images/facial_recognition.png'
const livenessImg =
  '../../assets/Business/ID Verification/Images/liveness_detection.png'
const useStyles = makeStyles((theme) => ({
  /**
   * || Reusable Components
   * ======================================= */
  sectionTitle: {
    marginBottom: 15,
    maxWidth: 280,

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 320,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 725,
    },
  },
  sectionDescription: {
    maxWidth: 350,

    color: 'rgba(115, 115, 115, 1)',

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 500,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
    },
  },

  /**
   * || Hero Section
   * ======================================= */
  hero: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    // height: 450,
    paddingTop: 96,
    marginBottom: 80,
    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,

    maxWidth: theme.custom.maxWidth,

    [theme.breakpoints.up('tablet')]: {
      paddingTop: 156,
      height: 520,
      justifyContent: 'left',
    },

    [theme.breakpoints.up('desktop')]: {
      height: 610,
      top: 20,
    },

    [theme.breakpoints.up('desktopXL')]: {
      // justifyContent: 'space-evenly',
      height: 410,
      top: 140,
    },

    '& .gatsby-image-wrapper': {
      display: 'none !important',

      [theme.breakpoints.up('tablet')]: {
        display: 'block !important',
        height: 'auto',
        position: 'relative',
        width: '40%',
        maxWidth: '230px',
      },

      [theme.breakpoints.up('desktop')]: {
        maxWidth: '300px',
      },

      [theme.breakpoints.up('desktopXL')]: {},
    },
  },
  heroContent: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',

    [theme.breakpoints.up('tablet')]: {
      // padding: 28,
      width: '60%',
    },

    [theme.breakpoints.up('desktop')]: {},
  },
  heroTitle: {
    textAlign: 'left',
    fontSize: '32px',
    color: 'black',

    [theme.breakpoints.up('tablet')]: {
      fontSize: '44px',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: '56px',
    },
  },
  heroDescription: {
    maxWidth: 340,
    fontSize: 16,
    margin: '20px 0px',

    color: 'rgba(115, 115, 115, 1)',

    textAlign: 'left',
    whiteSpace: 'pre-line',

    [theme.breakpoints.up('tablet')]: {
      textAlign: 'left',
      maxWidth: 520,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 520,
      fontSize: 18,
      textAlign: 'left',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      width: 'auto',
    },
  },

  signUpButton: {
    width: '90%',
    marginBottom: 10,
    fontSize: '16px',

    [theme.breakpoints.up('tablet')]: {
      marginRight: 10,
      fontSize: '14px',

      padding: 0,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '140px',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: '16px',
      width: '170px',
    },
  },

  /**
   * || Identity Management Introduction Section
   * ======================================= */
  introSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    // background: 'orange',

    marginTop: 50,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '70px 50px 50px 50px',
    maxWidth: '80%',

    // background: '#FFFFFF',
    // boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)',
    // borderRadius: 5,

    [theme.breakpoints.up('desktop')]: {
      marginTop: 130,
      maxWidth: '65%',
    },
    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 380,
    },
  },
  introTitle: {
    marginBottom: 15,
    maxWidth: 280,
    color: 'white',

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 344,
      fontSize: '18px',
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 725,
      fontSize: '27px',
    },
  },
  introDescription: {
    maxWidth: 350,
    color: 'white',
    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 562,
      fontSize: '12px',
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 458,
      fontSize: '16px',
    },
  },
  introBanner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 50,
    width: '180%',
    background:
      'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: theme.custom.maxWidth,
      padding: 70,
    },

    [theme.breakpoints.up('desktop')]: {
      padding: 100,
    },
  },
  introGrid: {
    display: 'grid',
    gridTemplate: '1fr 1fr 1fr 1fr / 1fr',

    marginTop: 50,
    rowGap: 25,

    [theme.breakpoints.up('tablet')]: {
      maxWidth: theme.custom.maxWidth,
      marginTop: 100,
    },

    [theme.breakpoints.up('desktop')]: {
      gridTemplate: ' 1fr 1fr / 1fr 1fr',

      columnGap: 100,
      rowGap: 40,
      marginTop: 150,
    },
  },
  introGridItem: {
    maxWidth: 525,

    '& svg': {
      float: 'left',

      marginRight: 10,
    },

    '& p': {
      clear: 'left',

      marginTop: 20,
    },
  },

  /**
   * || No Third-Party Section
   * ======================================= */
  noThirdPartySection: {
    position: 'relative', // To position the BG stripe
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    marginLeft: theme.gutters.mobile,
    marginRight: theme.gutters.mobile,
    marginTop: 60,

    [theme.breakpoints.up('desktop')]: {
      marginTop: 100,

      marginLeft: 0,
      marginRight: 0,
    },
  },
  thirdPartyBackground: {
    display: 'none',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: theme.custom.maxWidth,
      padding: 50,
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '180%',
      top: '50%',
      background:
        'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',
    },

    [theme.breakpoints.up('desktop')]: {
      padding: 80,
    },
  },

  noThirdPartyBG: {
    display: 'none',

    [theme.breakpoints.up('desktop')]: {
      display: 'block',
      position: 'absolute',

      width: '100%',
      height: '22%',
      top: '49%',

      backgroundColor: '#F0F4F8',

      zIndex: -1,
    },
  },
  noThirdPartyFlexContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    marginTop: 30,

    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      position: 'relative',

      height: 600,
      width: '100%',
    },
    [theme.breakpoints.up('desktopXL')]: {
      width: '100%',
      marginTop: 60,
    },
  },
  horizontalImage: {
    height: 'auto',
    width: '100%',
    maxWidth: 500,

    boxShadow: 'none',
    borderRadius: 25,

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 'unset',

      height: 395,
      width: 500,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginLeft: 60,
    },
  },
  verticalImage: {
    height: '550px',
    width: 295,
    maxWidth: 500,

    // boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.35)',
    borderRadius: 25,

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 'unset',

      height: 600,
      width: 311,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginLeft: 60,
    },
  },
  imageWrapper: {
    [theme.breakpoints.up('desktop')]: {
      width: 500,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  noThirdPartyCardContainer: {
    padding: 15,
    maxWidth: 375,
    marginBottom: 40,

    background: '#FFFFFF',
    boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.14)',
    borderRadius: 10,

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 375,
      marginBottom: 0,
    },

    '& svg': {
      float: 'left',

      marginRight: 10,
    },

    '& p': {
      clear: 'left',

      marginTop: 15,
    },
  },

  noThirdPartyCard: {
    padding: '15px',
    margin: '10px 0px',

    borderRadius: 5,
    borderLeft: '4px solid #ffffff', // Account for spacing with active border

    // Undo button defaults
    textTransform: 'none',
    display: 'initial',
    textAlign: 'left',
  },

  activeCard: {
    background: '#FFFFFF',
    boxShadow: ' 0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderLeft: '4px solid #38A743',
  },

  hideImage: {
    display: 'none !important',
  },
  showImage: {
    display: 'block !important',
  },

  /**
   * || Zero Knowledge Section
   * ======================================= */
  zeroKnowledgeSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    marginLeft: theme.gutters.mobile,
    marginRight: theme.gutters.mobile,
    marginTop: 100,

    [theme.breakpoints.up('desktop')]: {
      marginTop: 200,
      marginBottom: 180,
    },
  },
  zeroKnowledgeFlexContainer: {
    display: 'flex',
    flexDirection: 'column',

    marginTop: 20,

    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',

      width: '100%',
      maxWidth: 1100,
      marginTop: 50,
    },

    [theme.breakpoints.up('desktopXL')]: {
      width: '100%',
      maxWidth: 1350,
    },
  },
  zeroKnowledgeFeature: {
    margin: '20px 0px',
    padding: 10,
    maxWidth: 400,

    textAlign: 'center',

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 325,
    },

    [theme.breakpoints.up('desktopXL')]: {
      maxWidth: 400,
    },

    '& svg': {
      width: 150,
      heigth: 45,
    },

    '& p': {
      color: 'black',
    },
  },
  ctaBox: {
    marginTop: 100,
    marginBottom: 50,
    [theme.breakpoints.up('tablet')]: {
      marginBottom: 120,
    },
    [theme.breakpoints.up('desktop')]: {
      marginBottom: 190,
    },
  },
}))

const noThirdPartyCards = [
  {
    title: 'Document Verification',
    description:
      "ID documents are scanned by CycurID's OCR software to check for authenticity, then cross-referenced with multiple government data sources to ensure current and accurate information.",
    icon: <AuditIcon color='#2FA43B' style={{ height: 35 }} />,
    imageSrc: docVerifyImg,
    alt: 'Screenshot of the Imme document scanner interface',
  },
  {
    title: 'Facial Recognition',
    description:
      "CycurID secures personal information using our facial recognition AI. Our proprietary technology compares your face with pictures on your Drivers' License or Passport, verifying they belong to you.",
    icon: <FaceScanIcon color='#2FA43B' style={{ height: 35 }} />,
    imageSrc: facialRecognizeImg,
    alt: 'Graphic of the Imme authenication using comparison of document pictures and facial scan',
  },
  {
    title: 'Liveness Detection',
    description:
      'Fortify against spoofing, masks, and photos and make sure only real users are logging in with CycurID’s proprietary liveness detection AI software.',
    icon: <LivenessIcon color='#2FA43B' style={{ height: 35 }} />,
    imageSrc: livenessImg,
    alt: 'Screenshot of the simple and easy to use Imme liveness verification process',
  },
]

const introGridItems = [
  {
    title: 'Fortified Security',
    description:
      'Two-Factor Authentication, Document Verification, Biometric Recognition, and Liveness Detection synergize to create a reinforced system that keeps hackers on the outside.',
    icon: <SecurityIcon color='#2FA43B' style={{ height: 35 }} />,
  },
  {
    title: 'Continuous Automated KYC',
    description:
      'CycurID automatically performs KYC checks without any intervention so you always know who your business’ customers are.',
    icon: <KYCIcon color='#2FA43B' style={{ height: 35 }} />,
  },
  {
    title: 'Management-Free Data',
    description:
      "No personally identifying data changes hands, limiting your company's data liabilities and saving your business time and resources.",
    icon: <ManageDataIcon color='#2FA43B' style={{ height: 35 }} />,
  },
  {
    title: 'Audited Verification',
    description:
      "Report with confidence. CycurID's built-in auditing tools allow you to validate user verification checks, so your business meets its compliance requirements.",
    icon: <AuditIcon color='#2FA43B' style={{ height: 35 }} />,
  },
]

export default function IdentityVerification() {
  const theme = useTheme()
  const [activeCardIndex, setActiveCardIndex] = useState(0)
  const classes = useStyles({ activeCardIndex })
  const isTabletUp = useMediaQuery(theme.breakpoints.up('tablet'))

  return (
    <main>
      <section className={classes.hero}>
        <div className={classes.heroContent}>
          <Typography variant='h1' className={classes.heroTitle}>
            A Verification
          </Typography>
          {isTabletUp ? (
            <>
              <Typography variant='h1' className={classes.heroTitle}>
                Solution that
              </Typography>
              <Typography
                variant='h1'
                className={classes.heroTitle}
                style={{ color: '#2FA43B' }}
              >
                Boosts Your
              </Typography>
              <Typography
                variant='h1'
                className={classes.heroTitle}
                style={{ color: '#2FA43B' }}
              >
                Bottom Line Users
              </Typography>
            </>
          ) : (
            <>
              <Typography variant='h1' className={classes.heroTitle}>
                Solution that <span style={{ color: '#2FA43B' }}>Boost</span>
              </Typography>
              <Typography
                variant='h1'
                className={classes.heroTitle}
                style={{ color: '#2FA43B' }}
              >
                Your Bottom Line
              </Typography>
            </>
          )}

          <Typography variant='body1' className={classes.heroDescription}>
            Verifying users and transactions is vital to your ability to do
            business online. CycurID&apos;s AI-powered software doesn&apos;t
            just give you the tools you need to verify users yourself; our
            technology comprehensively handles the verification process for you.
          </Typography>
          <div className={classes.buttonContainer}>
            <CTALink
              className={classes.signUpButton}
              href={process.env.GATSBY_DASHBOARD_SIGNUP_LINK}
              target='_blank'
              rel='noopener'
            >
              Get Started
            </CTALink>
            <CTALink
              className={classes.signUpButton}
              href={routes.CONTACT}
              variant='outline'
            >
              Talk To Sales
            </CTALink>
          </div>
        </div>
        <StaticImage
          src={HeroImage}
          alt='Imme app facial recognition workflow'
          placeholder='blurred'
          loading='eager'
          quality={100}
        />
      </section>

      <section className={classes.introSection}>
        <div className={classes.introBanner}>
          <Divider style={{ marginBottom: 20 }} />
          <Typography variant='h2' className={classes.introTitle}>
            The Identity Management Advantage
          </Typography>
          <Typography variant='body1' className={classes.introDescription}>
            Through the power of reusable personal digital identity, CycurID
            provides industry leading verification solutions.
          </Typography>
        </div>
        <div className={classes.introGrid}>
          {introGridItems.map((item) => {
            return (
              <div className={classes.introGridItem} key={item.title}>
                {item.icon}
                <Typography
                  variant='h3'
                  style={{ marginTop: 10, marginBottom: 8 }}
                >
                  {item.title}
                </Typography>
                <Typography variant='body2' style={{ color: '#737373' }}>
                  {item.description}
                </Typography>
              </div>
            )
          })}
        </div>
      </section>

      {/* =====================> */}
      {/* =====================> */}
      {/* =====================> */}

      <section className={classes.noThirdPartySection}>
        <div className={classes.noThirdPartyBG} />
        <Divider style={{ marginBottom: 12 }} />
        <div className={classes.thirdPartyBackground} />
        <Typography variant='h2' className={classes.sectionTitle}>
          Proprietary AI-Driven Software
          <br />
          Industry-Leading Verification Across Diverse Verticals
        </Typography>
        <div className={classes.noThirdPartyFlexContainer}>
          <div className={classes.noThirdPartyCardContainer}>
            {noThirdPartyCards.map((card, index) => {
              return (
                <Button
                  key={card.title}
                  className={`${classes.noThirdPartyCard} ${
                    index === activeCardIndex ? classes.activeCard : ''
                  }`}
                  onClick={() => setActiveCardIndex(index)}
                >
                  {card.icon}
                  <Typography
                    variant='h3'
                    style={{ marginTop: 10, marginBottom: 8 }}
                  >
                    {card.title}
                  </Typography>
                  <Typography variant='body2' style={{ color: '#737373' }}>
                    {card.description}
                  </Typography>
                </Button>
              )
            })}
          </div>
          <div className={classes.imageWrapper}>
            <StaticImage
              className={`${classes.verticalImage} ${classes.hideImage} ${
                activeCardIndex === 0 ? classes.showImage : ''
              }`}
              src={docVerifyImg}
              alt={noThirdPartyCards[0].alt}
              placeholder='tracedSVG'
              loading='eager'
            />
            <StaticImage
              className={`${classes.horizontalImage} ${classes.hideImage} ${
                activeCardIndex === 1 ? classes.showImage : ''
              }`}
              src={facialRecognizeImg}
              alt={noThirdPartyCards[1].alt}
              objectFit='fill'
              placeholder='tracedSVG'
              loading='eager'
            />
            <StaticImage
              className={`${classes.verticalImage} ${classes.hideImage} ${
                activeCardIndex === 2 ? classes.showImage : ''
              }`}
              src={livenessImg}
              alt={noThirdPartyCards[2].alt}
              placeholder='tracedSVG'
              loading='eager'
            />
          </div>
        </div>
      </section>

      {/* =====================> */}
      {/* =====================> */}
      {/* =====================> */}

      <section className={classes.zeroKnowledgeSection}>
        <Divider style={{ marginBottom: 12 }} />
        <Typography variant='h2' className={classes.sectionTitle}>
          Zero-Knowledge Verification with Client-Side ID Management
        </Typography>
        <Typography variant='body1' className={classes.sectionDescription}>
          What we <b style={{ color: 'black' }}>don&apos;t</b> do with
          verification data is as important as what we do with it.
          CycurID&apos;s verification checks are performed entirely within our
          secured ecosystem. This way, your business receives industry-leading
          verification without the costs and downsides associated with it.
        </Typography>
        <div className={classes.zeroKnowledgeFlexContainer}>
          <div className={classes.zeroKnowledgeFeature}>
            <ZeroKnowledgeIcon1 />
            <Typography variant='body1'>
              Your anonymous customer inputs their personal information into the
              imme® app.
            </Typography>
          </div>
          <div className={classes.zeroKnowledgeFeature}>
            <ZeroKnowledgeIcon2 />
            <Typography variant='body1'>
              Verification checks are performed on their device, using
              CycurID&apos;s AI technology. Documents are scanned against
              government databases and sanction lists.
            </Typography>
          </div>
          <div className={classes.zeroKnowledgeFeature}>
            <ZeroKnowledgeIcon3 />
            <Typography variant='body1'>
              Your customer presents an anonymized but verified and validated
              token allowing secure zero-knowledge access to your platform.
            </Typography>
          </div>
        </div>
      </section>
      <CTABox
        title='Verifying users is an essential need for digital businesses. Make sure your company is properly equipped with the right solutions.'
        description={`CycurID does verification differently. Our solution is designed to handle the growing list of next-gen considerations your company has to be mindful of.${'\n\n'} Contact us, and overhaul how you verify your users.`}
        className={classes.ctaBox}
        buttons='none'
        bar='true'
      />
      <ContactUs />
    </main>
  )
}
